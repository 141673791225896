import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class ContractorService {
  addNew() { // Add a new contractor
    console.log('Add new contractor should be happening here');
    // TODO: Create new contractor object and open new contractor dialog form
  }
  
  constructor(private dialog: MatDialog) { }
}
